import React from "react";
import axios from "axios";
import {
  useTheme,
  TextField,
  Typography,
  Button,
  Container,
} from "@material-ui/core";

import Canvas from "../components/Canvas";

const Login = ({ onAuthenticate }) => {
  const theme = useTheme();
  const handleSubmit = (evt) => {
    evt.preventDefault();
    let tipusu = "A";

    if (evt.target.username.value.length === 14) {
      tipusu = "C";
    }

    const url = "https://www2.gboex.com.br/GBWebApi/api/v1/Token";

    const body = new URLSearchParams({
      grant_type: "password",
      tipusu: tipusu,
      username: evt.target.username.value,
      password: evt.target.password.value,
    });

    axios.post(url, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          onAuthenticate(response.data.access_token);
        } 
        console.log("the response", response);
        return
      }).catch( error => { 
        alert("Usuário ou Senha, não confere com o cadastrado no GBOEX.");
      });
  };

  return (
    <Canvas>
      <Container maxWidth="xs" style={{ marginTop: theme.spacing(6) }}>
        <Typography
          align="center"
          variant="h5"
          style={{ marginBottom: theme.spacing(4) }}
        >
          Faça login para continuar
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            variant="filled"
            label="usuário"
            name="username"
            color="secondary"
            helperText="Utilize seu cpf/cnpj ou matricula"
            style={{ marginBottom: theme.spacing(2) }}
          />

          <TextField
            fullWidth
            variant="filled"
            label="senha"
            name="password"
            type="password"
            color="secondary"
            helperText={
              <span>
                Perdeu a senha?{" "}
                <a href="https://portalservicos.gboex.com.br/user/recuperar-senha">
                  clique aqui.
                </a>
                <br />
                1º Acesso?{" "}
                <a href="https://portalservicos.gboex.com.br/user/primeiro-acesso">
                  clique aqui.
                </a>
              </span>
            }
            style={{ marginBottom: theme.spacing(2) }}
          />

          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            type="submit"
            style={{ marginTop: theme.spacing(4) }}
          >
            Entrar
          </Button>
        </form>
      </Container>
    </Canvas>
  );
};

export default Login;
