import React from 'react'

const Canvas = ({ children }) => (
  <div
    style={{
      minHeight: '100vh',
      background: '#f3f3f3',
      padding: '1px 0 30px',
    }}
  >
    {children}
  </div>
)

export default Canvas
