import React from 'react'
import axios from 'axios'
import lowerCase from 'lodash/lowerCase'
import { TextField, MenuItem, Grid, useTheme } from '@material-ui/core'

import CategoryIcon from './CategoryIcon'
import config from '../config'

const categories = [
  { icon: 'automotivo', label: 'Automotivo', id: 2 },
  { icon: 'beleza', label: 'Beleza', id: 1 },
  { icon: 'educacao', label: 'Educação', id: 4 },
  { icon: 'lojas', label: 'Lojas', id: 5 },
  { icon: 'servicos', label: 'Serviços', id: 6 },
  { icon: 'saude', label: 'Saúde', id: 3 },
  { icon: 'viagem', label: 'Viagens', id: 7 },
  { icon: 'entretenimento', label: 'Lazer', id: 13 },
  { icon: 'alimentacao', label: 'Alimentação', id: 10 },
  { icon: 'farmacias', label: 'Farmácias', id: 15 },
  { icon: 'hospitais', label: 'Hospitais e Clínicas', id: 12 },
  { icon: 'medicos', label: 'Médicos', id: 14 },
]

const useGetSubcategory = categoryId => {
  const [{ data, loading }, setLocalResponse] = React.useState({ data: [], loading: false })

  React.useEffect(() => {
    if (categoryId) {
      setLocalResponse({ loading: true, data: [] })

      axios({
        url: config.localApi,
        params: {
          action: 'getSubgroups',
          group: categoryId,
        }
      }).then(response => {
        setLocalResponse({ data: response.data.data, loading: false })
      })
    } else {
      setLocalResponse({ data: [], loading: false })
    }
  }, [categoryId])

  return {
    data,
    loading,
  }
}

const CategorySelector = ({ onChange }) => {
  const theme = useTheme()
  const [activeId, setActiveId] = React.useState(null)
  const [subgroup, setSubgroup] = React.useState('')
  const { data, loading } = useGetSubcategory(activeId)

  const handleToggle = React.useCallback(selected => {
    const newValue = activeId !== selected ? selected : null
    setActiveId(newValue)
    setSubgroup('')
    onChange({ group: newValue, subgroup: '' })
  }, [onChange, activeId])

  const handleSubgroup = React.useCallback(event => {
    setSubgroup(event.target.value)
    onChange({ group: activeId, subgroup: event.target.value })
  }, [activeId, onChange])

  const showSubgroup = !loading && !!data.length

  return (
    <Grid container spacing={1} style={{ margin: theme.spacing(3, 0) }}>
      {categories.map(category => (
        <Grid item xs={4} sm={2} key={`category-${category.id}`}>
          <CategoryIcon
            activeId={activeId}
            onClick={handleToggle}
            category={category}
          />
        </Grid>
      ))}

      {showSubgroup && (
        <Grid item xs={12}>
          <TextField
            fullWidth
            select
            variant="filled"
            name="subcategory"
            label="Selecione o subgrupo"
            value={subgroup}
            onChange={handleSubgroup}
          >
            {data.map(item => (
              <MenuItem key={`subgroup-${item.id}`} value={item.id}>
                {lowerCase(item.nome)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      )}
    </Grid>
  )
}

export default React.memo(CategorySelector)