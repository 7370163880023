import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'

import theme from './theme'
import Login from './pages/Login'
import Search from './pages/Search'

import { useCookies } from 'react-cookie';

function App() {
  const [token, setToken] = React.useState(null)
  const handleAuth = newToken => setToken(newToken)
  const [cookies, setCookie] = useCookies(['name']);

  if(token){
    setCookie('name', token, { path: '/' });
  }

  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />

      {cookies.name ? (
        <Search />
      ) : (
        <Login onAuthenticate={handleAuth} />
      )}
    </ThemeProvider>
  );
}

export default App;
