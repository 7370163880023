import React from 'react'
import { Container } from '@material-ui/core'

import ResultItem from './ResultItem'

const ResultList = ({ loading, items }) => {
  return (
    <Container>
      {items.map((item, idx) => (
        <ResultItem key={`result-card-${idx}`} item={item} />
      ))}
    </Container>
  )
}

export default ResultList
