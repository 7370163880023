import React from 'react'
import clsx from 'clsx'
import { ButtonBase, withStyles } from '@material-ui/core'

const images = {
  automotivo: require('../assets/automotivo.png'),
  beleza: require('../assets/beleza.png'),
  educacao: require('../assets/educacao.png'),
  lojas: require('../assets/lojas.png'),
  servicos: require('../assets/servicos.png'),
  saude: require('../assets/saude.png'),
  viagem: require('../assets/viagem.png'),
  entretenimento: require('../assets/entretenimento.png'),
  alimentacao: require('../assets/alimentacao.png'),
  farmacias: require('../assets/farmacias.png'),
  hospitais: require('../assets/hospitais.png'),
  medicos: require('../assets/medicos.png'),
}

const CategoryIcon = ({ classes, category, activeId, onClick }) => {
  const buttonClassName = [classes.root]

  if (activeId !== null) {
    buttonClassName.push(activeId !== category.id && classes.inactive)
  }

  return (
    <ButtonBase
      className={clsx(buttonClassName)}
      onClick={() => onClick(category.id)}
      TouchRippleProps={{ classes: { child: classes.ripple } }}>
      <div className={classes.inner}>
        <img src={images[category.icon]} alt={category.label} />
      </div>
    </ButtonBase>
  )
}

const styles = theme => ({
  root: {
    height: 0,
    width: '100%',
    paddingBottom: '100%',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
  },
  ripple: {
    backgroundColor: '#fff'
  },
  inactive: {
    filter: 'grayscale(0.7)',
    opacity: 0.9
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: '#1c5b82',
    borderRadius: theme.shape.borderRadius,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  title: {
    fontSize: 13,
    color: theme.palette.primary.contrastText,
  }
})

export default withStyles(styles)(CategoryIcon)
