import React from 'react'
import axios from 'axios'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import { useTheme, Container, Button, Grid, CircularProgress, TextField } from '@material-ui/core'

import Canvas from '../components/Canvas'
import CitySelectors from '../components/CitySelectors'
import CategorySelector from '../components/CategorySelector'
import ResultList from  '../components/ResultList'

import config from '../config'

const useGetConveniados = formState => {
  const pageRef = React.useRef(0)
  const [{ items, loading, loadmore, loadtxt }, setLocalResponse] = React.useState({ items: [], loading: false, loadmore: false, loadtxt: false })

  const query = () => {
    setLocalResponse({ loading: true, items: [] })
    pageRef.current = 1
    let showBtnLoadMore = false;

    axios({
      url: config.localApi,
      params: {
        action: 'getConveniados',
        page: 1,
        ...pickBy(formState, identity),
      }
    }).then(response => {
      const data = response.data.data;
      //console.log(data);
      if(data.length){
        if(data.length >= 20){
          showBtnLoadMore = true;
        }
        //console.log(showBtnLoadMore);

        setLocalResponse({ items: data, loading: false, loadmore: showBtnLoadMore })
      }else{
        setLocalResponse({loading: false})
      }
    })
  }
  const loadMore = () => {
    setLocalResponse({ loading: true, items: items, loadmore:true, loadtxt:true})
    pageRef.current += 1
    let showBtnLoadMore = false;

    axios({
      url: config.localApi,
      params: {
        action: 'getConveniados',
        page: pageRef.current,
        ...pickBy(formState, identity),
      }
    }).then(response => {
      const data = response.data.data;
      
      if(data.length){
        if(data.length >= 20){
          showBtnLoadMore = true;
        }
        setLocalResponse({ items: items.concat(response.data.data), loading: false, loadmore:showBtnLoadMore, loadtxt:false })
      }else{
        setLocalResponse({loading: false, loadmore:false, loadtxt:false })
      }
    })
  }

  const clear = () => {
    window.location.reload()
  }

  return {
    query,
    clear,
    loadMore,
    items,
    loading,
    loadmore,
    loadtxt
  }
}

const Search = () => {
  const theme = useTheme()
  let formState = React.useRef({})
  const { query, clear, items, loading, loadMore, loadmore, loadtxt } = useGetConveniados(formState.current)

  const handleChange = React.useCallback(newItems => {
    formState.current = Object.assign(formState.current, newItems)
  }, [])


  const keyboardEvent = (e) => {
    if (e.keyCode === 13) {
      query()
    }
  }

  return (
    <Canvas>
      <Container maxWidth="md" style={{ marginTop: theme.spacing(6) }}>
        <CitySelectors onChange={handleChange} />
        <CategorySelector onChange={handleChange} />

        <TextField
          fullWidth
          variant="filled"
          label="Buscar por nome"
          onChange={evt => handleChange({ term: evt.target.value })}
          onKeyUp={keyboardEvent}
        />
        {loading ? (
          <Grid container justify="center" style={{ marginTop: 25 }}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
          <Button
            variant="contained"
            color="primary"
            style={{
              padding: theme.spacing(2, 8),
              left: '250px',
              top: '25px',
              marginBottom: '50px'
            }}
            onClick={query}
            id="searchButton"
          >
            Buscar
          </Button>
          <Button
            variant="contained"
            color="inherit"
            style={{
              padding: theme.spacing(2, 8),
              left: '260px',
              top: '25px',
              marginBottom: '50px'
            }}
            onClick={clear}
            type="submit"
          >
            Limpar
          </Button>
        </>
        )}

        <ResultList loading={loading} items={items || []} />

        { loadmore ? (<Button
          variant="contained"
          color="inherit"
          style={{
            padding: theme.spacing(2, 8),
            left: '260px',
            top: '25px',
            marginBottom: '50px'
          }}
          onClick={loadMore}

        >
          {loadtxt ? 'Aguarde. Carregando... ': '[+] Ver Mais'}
        </Button>) : '' }



      </Container>
    </Canvas>
  )
}

export default Search
