import { createMuiTheme } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#034661',
    },
    secondary: blue,
    text: {
      primary: '#034661',
    }
  },
  shape: {
    borderRadius: 2
  }
})

export default theme