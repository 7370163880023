import React from 'react'
import { Grid, TextField, MenuItem } from '@material-ui/core'
import { estados, cidades } from '../allCities'

const CitySelector = ({ onChange }) => {
  const [uf, setUf] = React.useState('')
  const [city, setCity] = React.useState('')

  React.useEffect(() => {
    onChange({ uf, city })
  }, [onChange, uf, city])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="filled"
          select
          label="Selecione o estado"
          name="uf"
          value={uf}
          onChange={item => {
            setUf(item.target.value)
            setCity('')
          }}
        >
          {estados.map((state, idx) => (
            <MenuItem key={`state-${idx}`} value={state.value}>
              {state.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="filled"
          select
          label="Selecione a cidade"
          name="city"
          value={city}
          onChange={item => {
            setCity(item.target.value)
          }}
        >
          {(cidades[uf] || []).map((city, idx) => (
            <MenuItem key={`city-${idx}`} value={city}>
              {city}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    </Grid>
  )
}

export default React.memo(CitySelector)