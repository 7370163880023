import React from 'react'
import clsx from 'clsx'

import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Avatar,
  withStyles
} from '@material-ui/core'
import PhoneIcon from '@material-ui/icons/Phone'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import EmailIcon from '@material-ui/icons/Email'

const Actions = ({ item }) => (
  <CardActions style={{ flexWrap: 'wrap' }}>
    {item.telefone1 && (
      <Button
        component="a"
        target="_blank"
        href={`tel://${item.telefone1}`}
        startIcon={<PhoneIcon />}
      >
        {item.telefone1}
      </Button>
    )}
    {item.telefone2 && (
      <Button
        component="a"
        target="_blank"
        href={`tel://${item.telefone2}`}
        startIcon={<PhoneIcon />}
      >
        {item.telefone2}
      </Button>
    )}
    {item.email && (
      <Button
        component="a"
        target="_blank"
        href={`mailto://${item.email}`}
        startIcon={<EmailIcon />}
        style={{textTransform: 'lowercase'}}
      >
        {item.email}
      </Button>
    )}
    {item.site && (
      <Button
        component="a"
        target="_blank"
        href={`http://${item.site}`}
        endIcon={<OpenInNewIcon />}
      >
        {item.site}
      </Button>
    )}
  </CardActions>
)

const ResultItem = ({ classes, item }) => (
  <Card className={classes.root}>
    {!!item.logo && (
      <Avatar
        variant="square"
        src={`data:image/jpeg;base64,${item.logo}`}
        style={{ paddingTop: 8, paddingLeft: 24, width: 245, height: 'auto',float: 'right' } }
      />
    )}
    <CardContent Style="padding: 20px 0px 0px 15px!important;margin-bottom: -15px;">
      <Typography variant="button" display="block" color="textSecondary">
        {item.ramo}
      </Typography>
      <Typography variant="h6" className={classes.text}>
        {item.nome}
      </Typography>
      <Typography variant="subtitle2" className={classes.text}>
        {`${item.endereco}, ${item.bairro}`}<br/>{`${item.cidade} - ${item.uf}`}
      </Typography>
      <Typography className={clsx([classes.text, classes.description])}>
        DESCONTO/VANTAGENS: {item.desconto}
      </Typography>
    </CardContent>
    <Actions item={item} Style="padding: 0px 0px 0px 8px!important;"></Actions> 
  </Card>
)

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  text: {
    color: '#000',
  },
  description: {
    margin: theme.spacing(2, 0),
  }
})

export default withStyles(styles)(ResultItem)
